<template>
  <section>
    <b-card>
      <TablePagination
        v-model="items"
        url="/admin/users/paginate"
        :per-page="10"
        :fields="fields"
        default-filter-by="email"
      >
        <template v-slot:id="data">
          {{ data.items.id }}
        </template>

        <template v-slot:name="data">
          {{ data.items.personal_data.full_name }}
        </template>

        <template v-slot:email="data">
          {{ data.items.email }}
        </template>

        <template v-slot:phone="data">
          {{ data.items.personal_data.phone }}
        </template>

        <template v-slot:balance="data">
          {{ data.items.balance.amount | currency }}
        </template>

        <template v-slot:actions="data">
          <b-button
            class="mr-1"
            size="sm"
            variant="relief-primary"
            @click.stop="view(data.items.id)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>

          <b-button
            class="mr-1"
            size="sm"
            variant="relief-primary"
            @click.stop="open(data.items)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            class="mr-1"
            size="sm"
            variant="relief-primary"
            @click.stop="logInPerId(data.items.id)"
          >
            <feather-icon icon="LogInIcon" />
          </b-button>
        </template>
      </TablePagination>
    </b-card>

    <Edit
      v-if="editModal"
      v-model="editModal"
      :data="userData"
    />
  </section>
</template>

<script>
import { BCard, BButton, BSpinner } from 'bootstrap-vue'
import TablePagination from '@/components/TablePagination/Index.vue'
import Edit from './View/Edit.vue'
import logInPerId from '@/mixins/logInPerId'

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    TablePagination,
    Edit
  },
  filters: {
    currency (val) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })

      return formatter.format(val)
    }
  },
  mixins: [logInPerId],
  data: () => ({
    editModal: false,
    userData: {},
    items: [],
    fields: [
      {
        key: 'id',
        label: 'id',
        sortable: true
      },
      {
        key: 'name',
        label: 'nome',
        sortable: true
      },
      {
        key: 'email',
        label: 'email',
        sortable: true
      },
      {
        key: 'phone',
        label: 'Whatsapp',
        sortable: true
      },
      {
        key: 'balance',
        label: 'Saldo',
        sortable: true
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  }),
  methods: {
    view (id) {
      this.$router.push({ name: 'Visualizar Usuário (Admin)', params: { id } })
    },
    open (item) {
      this.userData = item
      this.editModal = true
    }
  }
}
</script>
