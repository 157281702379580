<template>
  <b-modal
    v-model="visible"
    title="Editar Conta"
    cancel-variant="outline-secondary"
    centered
    @change="(val) => $emit('update:visible', val)"
  >
    <b-row v-if="show">
      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-group
          label-size="md"
          label-align="left"
          label="Email"
          label-for="defaultLabel"
        >
          <b-form-input
            id="defaultLabel"
            v-model="userData.personal_data.full_name"
            class="w-100"
            placeholder=""
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-group
          label-size="md"
          label-align="left"
          label="Email"
          label-for="defaultLabel"
        >
          <b-form-input
            id="defaultLabel"
            v-model="userData.email"
            class="w-100"
            placeholder=""
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-group
          label-size="md"
          label-align="left"
          label="Telefone"
          label-for="defaultLabel"
        >
          <b-form-input
            id="defaultLabel"
            v-model="userData.personal_data.phone"
            class="w-100"
            placeholder=""
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
      >
        <b-form-select
          id="service-type"
          v-model="userData.balance.level"
          :state="userData.balance.level === null ? false : true"
          :options="options"
        >
          <template #first>
            <b-form-select-option
              :value="null"
            >Por favor, selecione um tipo!</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-button
        variant="relief-primary"
        :disabled="loading"
        block
        @click.stop="edit()"
      >
        <span v-if="!loading">Salvar</span>
        <b-spinner
          v-else
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BButton, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BSpinner
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BSpinner
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: false,
    show: false,
    options: [],
    userData: {}
  }),
  mounted () {
    this.getDiscountOptions()

    this.userData = JSON.parse(
      JSON.stringify(this.data)
    )

    this.show = true
  },
  methods: {
    async getDiscountOptions () {
      const url = '/admin/discount_level/get_options'

      await this.$http.get(url)
        .then(res => {
          this.options = res.data
        })
    },
    async edit () {
      this.loading = true

      const url = `/admin/users/${this.data.id}`
      const params = {
        name: this.userData.personal_data.full_name,
        email: this.userData.email,
        phone: this.userData.personal_data.phone,
        discount_level: this.userData.balance.level
      }

      await this.$http.patch(url, params)
        .then(() => {
          this.$swal({
            title: 'Conta alterada com sucesso!',
            icon: 'success',
            confirmButtonText: 'Fechar',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .finally(() => {
          this.loading = false
          this.$emit('update:visible', false)
          this.$emit('updated', true)
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
