import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

export default authToken => {
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '639582a0a9ab1d84b25c',
    cluster: 'mt1',
    disableStats: true,
    authEndpoint: `${process.env.VUE_APP_HOST_API_CLEAN}broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/json'
      }
    }
  })
}
