import useJwt from '@/auth/jwt/useJwt'
import laravelEcho from '@/libs/laravel-echo'

export default {
  computed: {
    hasOldLogin () {
      const accessToken = localStorage.getItem('oldAccessToken')
      const userData = JSON.parse(localStorage.getItem('oldUserData'))

      return accessToken !== null || userData !== null
    }
  },
  methods: {
    async logInPerId (id) {
      const url = `/admin/users/log_in_per_id/${id}`

      this.storeOldToken()

      await this.$http.get(url)
        .then(res => {
          const { userData } = res.data
          useJwt.setToken(res.data.accessToken)
          laravelEcho(res.data.accessToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$ability.update(userData.ability)

          this.$router.push({ name: 'Dashboard' })
            .finally(() => {
              window.location.reload()
            })
        })
    },
    storeOldToken () {
      // getting present data
      const accessToken = localStorage.getItem('accessToken')
      const userData = localStorage.getItem('userData')

      // Remove old datas
      localStorage.removeItem('oldAccessToken')
      localStorage.removeItem('oldUserData')

      // storage new old datas
      localStorage.setItem('oldAccessToken', accessToken)
      localStorage.setItem('oldUserData', userData)
    },
    backToOldToken () {
      // getting old data
      const accessToken = localStorage.getItem('oldAccessToken')
      const userData = JSON.parse(localStorage.getItem('oldUserData'))

      // Remove old datas
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userData')
      localStorage.removeItem('oldAccessToken')
      localStorage.removeItem('oldUserData')

      useJwt.setToken(accessToken)
      laravelEcho(accessToken)
      localStorage.setItem('userData', JSON.stringify(userData))
      this.$ability.update(userData.ability)

      window.location.reload()
    }
  }
}
